<template>
	<div>
		<div class='a-comparison'>
			<div class="app-container a-comparison-container">
				<div class="a-comparison-header">
					<div class="row align-items-center">
						<div class='col-auto a-comparison-back'>
							<div class='a-comparison-back-text' @click='backPage'>
								<v-icon icon='arrow-prev' size='10' />
								{{$t('account.comparison.back_to_search')}}
							</div>
						</div>
						<div class="col">
							<p class='a-heading-1'>{{$t('account.comparison.title.charter_flight')}} {{comparison.count ? `(${comparison.count} ${$t('account.with')} ${comparison.maxCount})` : ""}}</p>
						</div>
						<a-comparison-action :filename="`${$t('account.comparison.title.charter_flight')} ${comparison.count ? `(${comparison.count} ${$t('account.with')} ${comparison.maxCount})` : ''}`" :link="copyLink">
							<template v-slot:share>
								<section class="pdf-item">
									<p class='a-pdf-text'>{{$t('account.trips.prices_are_for_reference_only')}} <span class='a-pdf-date'>{{$moment().format("DD/MM/YYYY HH:MM")}}</span></p>
									<p class='a-pdf-title'>{{$t('account.comparison.title.charter_flight')}} {{comparison.count ? `(${comparison.count} ${$t('account.with')} ${comparison.maxCount})` : ""}}</p>
									<p class='a-pdf-sub-title'>
										{{$moment($_.head(comparison.searchData.directions).dateFrom).format("DD/MM/YYYY")}}
										{{comparison.searchData.type === "both" ? " - " + $moment($_.head(comparison.searchData.directions).dateTo).format("DD/MM/YYYY") : null}}
										{{comparison.searchData.type === "complex_route" ? " - " + $moment($_.last(comparison.searchData.directions).dateFrom).format("DD/MM/YYYY") : null}}
									</p>
								</section>

								<section class='pdf-item' v-for='(item, i) in items' :key='i'>
									<div class="a-timeline-wrap">
										<a-card-flights
											:item='item'
											:searchData='comparison.searchData'
										/>
									</div>
								</section>
							</template>
						</a-comparison-action>
					</div>
				</div>

				<template v-if='items.length > 0'>
					<div v-for='(item, i) in items' :key='i'>
						<trips-card
							:item='item'
							:check_availability='check_availability'
							@update-comparison='check_availability = $event, comparison = $event'
							@open-details='openDetails(item)'
							@open-offer='openOffer(item)'
							:hideCheckbox='true'
						/>
					</div>
				</template>

				<template v-else>
					<trips-preloader
						name='flights'
						animation='flights'
						:title='$t("account.search.preloader.charter_flight.no_found")'
					/>
				</template>
			</div>
		</div>

		<trips-card-details
			v-model='card_details'
			:card='active_card'
			@open-offer='addOrder(active_card)'
			:requestName='charterFlightDetails'
			hideTariff
		/>
	</div>
</template>

<script>
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsCard from '@/views/account/search/flights/card';
	import TripsCardDetails from '@/views/account/details/flights';
	import { getComparison, comparisonCheckAvailability, addOrder, charterFlightDetails } from '@/services/request';
	import AComparisonAction from '@/views/account/comparison/components/action';
	import ACardFlights from '@/views/account/comparison/pdf-card/flights';

	export default {
		data: () => ({
			comparison: {},
			check_availability: {},
			active_card: null,
			card_details: false
		}),
		components: {
			TripsPreloader,
			TripsCard,
			TripsCardDetails,
			AComparisonAction,
			ACardFlights
		},
		computed: {
			source() {
				if(!this.$route.query.source) return;
				return JSON.parse(this.$route.query.source);
			},
			items() {
				return this.comparison.items ? Object.values(this.comparison.items) : [];
			},
			copyLink() {
				return location.origin + '/share-charter-flight/' + this.$route.params.comparisonId
			}
		},
		created() {
			this.comparisonCheckAvailability();
			this.getComparison();
		},
		methods: {
			charterFlightDetails,
			comparisonCheckAvailability() {
				comparisonCheckAvailability({
					type: 'charterFlight',
					orderId: this.$route.query.orderId,
					storageId: this.$route.query.storageId
				}).then(res => this.check_availability = res.data ?? {});
			},
			getComparison() {
				getComparison(this.$route.params.comparisonId).then(res => this.comparison = res.data ?? {});
			},
			openDetails(item) {
				this.card_details = true;
				this.active_card = item;
			},
			openOffer(item) {
				this.card_details = true;
				this.active_card = item;
			},
			addOrder(item) {
				this.loading_btn = true;
				let replaceOfferId = this.source.replaceOfferId ? { replaceOfferId: this.source.replaceOfferId } : false;

				addOrder({
					type: "charterFlight",
					orderId: this.$route.query.orderId,
					offerId: item.code,
					storageId: this.$route.query.storageId,
					...replaceOfferId
				}).then(res => {
					this.$router.push({
						name: "itinerary",
						params: {orderId: res.data.id, lang: this.$route.params.lang},
					})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			},
			backPage() {
				this.$router.push({
					name: "charter-flight-search",
					params: {orderId: this.comparison.orderId, storageId: this.comparison.storageId, lang: this.$route.params.lang},
					query: {source: this.$route.query.source}
				})
			}
		}
	}
</script>
